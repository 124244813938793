// import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils';

export const SectionStyled = styled.section`
  // background: ${({ theme }) => theme.colors.mainBackgroundColor};
`;

export const ContainerStyled = styled.div`
  ${'' /* box-shadow: inset 0 0 0 1px rgba(0,0,0,0.4); */}
  max-width: 120rem;
  margin: 0 auto;
`;

export const HomeWrap = styled.div`
  color: var(--golden-gate-bridge);
  width: fit-content;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: 300;
  gap: 3rem;
  padding: 0 3rem;

  @media ${device.sm} {
    width: 56rem;
  }

  @media ${device.md} {
    padding: 0;
  }

  @media ${device.lg} {
    width: 70rem;
  }

  &,
  .wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .wrap {
    gap: 0.8rem 0;
  }

  h2 {
    line-height: 1;
    font-family: 'Okine-Outline';
    font-size: 4rem;
    font-weight: 400;
    color: hsla(225, 25%, 10%, 1);

    @media ${device.md} {
      font-size: 5rem;
    }
  }

  .wrap span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0 1rem;
    font-size: 2rem;
    font-weight: 400;

    svg {
      display: block;
    }
  }

  h2 + p,
  h3 + p,
  p + p {
    margin-block: 0;
  }

  .red-text {
    color: var(--golden-gate-bridge);
  }

  .blue-text {
    color: var(--nav-dark-blue);
  }

  p,
  .p-div {
    text-wrap: balance;

    &.has-img {
      position: relative;
      text-wrap: wrap;
      display: flex;
      flex-flow: column nowrap;
      gap: 2rem 1.5rem;

      a:has(.gatsby-image-wrapper) {
        width: 112px;
        height: 112px;
        display: block;
        border-radius: 0.25rem;
        transition:
          background 250ms ease-out,
          box-shadow 250ms ease-out;

        .gatsby-image-wrapper {
          border-radius: 0.4rem;
          width: 112px;
          height: 112px;
        }

        &:hover {
          background: ${({ theme }) => theme.colors.brightYellow};
          box-shadow: 0 0 0 0.3rem ${({ theme }) => theme.colors.brightYellow};
        }
      }

      @media ${device.sm} {
        flex-flow: row nowrap;
        align-items: center;
      }
    }
  }
`;

export const HowItWorksWrap = styled(HomeWrap)`
  gap: 0;

  h2 {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    line-height: 1;
    position: relative;
    font-size: 3rem;
    transition: color 250ms ease;

    svg {
      /* background-color: orange; */
      height: 4rem;
      width: 4rem;
      stroke-width: 0.1rem;
      transition: color 250ms ease;
      /*  */
    }

    &:hover {
      color: var(--golden-gate-bridge);
    }

    &.blue-text svg,
    &.blue-text:hover {
      color: var(--nav-dark-blue);
    }

    @media ${device.sm} {
      font-size: 4rem;
    }
  }

  p + h2 {
    margin-block-start: 4rem;
  }

  h1 + p,
  h2 + p {
    margin-block-start: 1.5rem;
  }

  p:last-child {
    margin-block-end: 4rem;
  }

  h1 {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px 0;
    margin: 0;
    line-height: 1;
    font-family: 'Okine-Outline';
    font-size: 4.5rem;
    font-weight: 300;
    // color: ${({ theme }) => theme.colors.brightYellow};
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    padding: 10rem 0 0 0;

    @media ${device.sm} {
      font-size: 6rem;
    }

    @media ${device.lg} {
      font-size: 8rem;
    }
  }

  @media ${device.md} {
    h2 {
      gap: 0;
    }
    h2 svg {
      position: absolute;
      right: calc(100% + 3rem);
      top: calc(50% + 0.4rem);
      transform: translateY(-50%);
    }
    h2 + p {
      position: relative;
    }
    h2:not(.blue-text) + p:after {
      content: '';
      position: absolute;
      right: calc(100% + 5rem);
      top: 0;
      width: 0.1rem;
      height: calc(100% + 3rem);
      background: ${({ theme }) => theme.colors.veryDarkBlue};
      opacity: 0.2;
    }
  }
`;
