import React from 'react';
import styled from 'styled-components';

import { RedLineLink } from '../../components/Button';
import { ContainerStyled } from '../Kk/kk-components';
import Social from '../Social';
import { device } from '../../utils';

const ContactBtn = styled(RedLineLink)`
  font-size: 1.6rem;
  font-weight: 300;
  padding: 10px 12px;
  span {
    height: 1.6rem;
    line-height: 1.6rem;
  }
`;

const socialItems = [
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/kristiankeane/'
  },
  {
    title: 'YouTube',
    url: 'https://www.youtube.com/playlist?list=PLP1EJrg9tBlF1nzb1tZkARqF8DjIQoPi1'
  }
];

const FooterBox = styled.footer`
  padding: 4rem 0 4rem 0;
  border-top: 0.1rem solid rgba(125,125,125,0.2);

  .copyright {
    color: ${({ theme }) => theme.colors.brightYellow };
    font-size: 1.4rem;
    font-weight: 300;
    text-align: right;

    @media ${ device.sm } {
      font-size: 1.6rem;
    }
  }
`;

const FooterContainer = styled(ContainerStyled)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  padding: 0 2rem;
  /* max-width: unset; */

  @media ${ device.sm } {
    padding: 0 4rem;
  }
  .footer-left {

  }
  .footer-right {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    gap: 2rem 0;
  }
`;

const Footer = ({ isDark = true, ...rest }) => {
  return (
    <FooterBox>

      <FooterContainer>

        <div className="footer-left">

          <ContactBtn to="/contact"><span>get in touch</span></ContactBtn>

        </div>

        <div className="footer-right">

          <Social items={ socialItems } inverse={ !isDark } isFooter={ true } />

          <div className="copyright">© 2024 chairlift creative</div>

        </div>

      </FooterContainer>

    </FooterBox>
  );
};

export default Footer;
